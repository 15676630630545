const usStates = [
    { value: 'ALABAMA', key: 'Alabama' },
    { value: 'ALASKA', key: 'Alaska' },
    { value: 'AMERICAN SAMOA', key: 'American Samoa' },
    { value: 'ARIZONA', key: 'Arizona' },
    { value: 'ARKANSAS', key: 'Arkansas' },
    { value: 'CALIFORNIA', key: 'California' },
    { value: 'COLORADO', key: 'Colorado' },
    { value: 'CONNECTICUT', key: 'Connecticut' },
    { value: 'DELAWARE', key: 'Delaware' },
    { value: 'DISTRICT OF COLUMBIA', key: 'District of Columbia' },
    { value: 'FEDERATED STATES OF MICRONESIA', key: 'Federated States of Micronesia' },
    { value: 'FLORIDA', key: 'Florida' },
    { value: 'GEORGIA', key: 'Georgia' },
    { value: 'GUAM', key: 'Guam' },
    { value: 'HAWAII', key: 'Hawaii' },
    { value: 'IDAHO', key: 'Idaho' },
    { value: 'ILLINOIS', key: 'Illinois' },
    { value: 'INDIANA', key: 'Indiana' },
    { value: 'IOWA', key: 'Iowa' },
    { value: 'KANSAS', key: 'Kansas' },
    { value: 'KENTUCKY', key: 'Kentucky' },
    { value: 'LOUISIANA', key: 'Louisiana' },
    { value: 'MAINE', key: 'Maine' },
    { value: 'MARSHALL ISLANDS', key: 'Marshall Islands' },
    { value: 'MARYLAND', key: 'Maryland' },
    { value: 'MASSACHUSETTS', key: 'Massachusetts' },
    { value: 'MICHIGAN', key: 'Michigan' },
    { value: 'MINNESOTA', key: 'Minnesota' },
    { value: 'MISSISSIPPI', key: 'Mississippi' },
    { value: 'MISSOURI', key: 'Missouri' },
    { value: 'MONTANA', key: 'Montana' },
    { value: 'NEBRASKA', key: 'Nebraska' },
    { value: 'NEVADA', key: 'Nevada' },
    { value: 'NEW HAMPSHIRE', key: 'New Hampshire' },
    { value: 'NEW JERSEY', key: 'New Jersey' },
    { value: 'NEW MEXICO', key: 'New Mexico' },
    { value: 'NEW YORK', key: 'New York' },
    { value: 'NORTH CAROLINA', key: 'North Carolina' },
    { value: 'NORTH DAKOTA', key: 'North Dakota' },
    { value: 'NORTHERN MARIANA ISLANDS', key: 'Northern Mariana Islands' },
    { value: 'OHIO', key: 'Ohio' },
    { value: 'OKLAHOMA', key: 'Oklahoma' },
    { value: 'OREGON', key: 'Oregon' },
    { value: 'PALAU', key: 'Palau' },
    { value: 'PENNSYLVANIA', key: 'Pennsylvania' },
    { value: 'PUERTO RICO', key: 'Puerto Rico' },
    { value: 'RHODE ISLAND', key: 'Rhode Island' },
    { value: 'SOUTH CAROLINA', key: 'South Carolina' },
    { value: 'SOUTH DAKOTA', key: 'South Dakota' },
    { value: 'TENNESSEE', key: 'Tennessee' },
    { value: 'TEXAS', key: 'Texas' },
    { value: 'UTAH', key: 'Utah' },
    { value: 'VERMONT', key: 'Vermont' },
    { value: 'VIRGIN ISLANDS', key: 'Virgin Islands' },
    { value: 'VIRGINIA', key: 'Virginia' },
    { value: 'WASHINGTON', key: 'Washington' },
    { value: 'WEST VIRGINIA', key: 'West Virginia' },
    { value: 'WISCONSIN', key: 'Wisconsin' },
    { value: 'WYOMING', key: 'Wyoming' },
];

export const usStatesWithNationWide = [{ value: 'NATIONWIDE', key: 'Nationwide' }, ...usStates];

export default usStates;
